<template>
  <div class="faq-page">
    <div class="faq-page-title">
      <div class="container">FAQ</div>
    </div>
    <div class="article container">
      <div class="article-title">{{ faqDetail.question }}</div>
      <div class="article-content" v-html="faqDetail.answer"></div>
    </div>
  </div>
</template>

<script>
  import {detail} from '@/api/faq';

  export default {
    name: 'faq',
    data() {
      return {
        faqDetail: {},
      };
    },
    mounted() {
      this.fetch();
    },
    methods: {
      fetch() {
        detail({id: this.$route.params.id}).then(res => {
          this.faqDetail = res.data.data;
        });
      },
    },
  };
</script>

<style scoped lang="less">
  .faq-page {
    position: relative;
    padding-bottom: 20px;
    background-color: #F8F8F8;

    &:after {
      content: '';
      background-color: #F8F8F8;
      position: absolute;
      height: 100vh;
      left: 0;
      top: 0;
      right: 0;
      z-index: -1;
    }

    @media (min-width: 1200px) {
      .container {
        width: auto;
        max-width: 1440px;
      }
    }


    .faq-page-title {
      font-size: 50px;
      font-weight: 400;
      color: #3D3D3D;
      padding: 12px 0;
    }

  }

  .article {
    .article-title {
      min-height: 76px;
      background: #F1F1F1;
      padding: 20px 50px;
      line-height: 1.5;
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      color: #000000;
    }
    .article-content{
      height: 717px;
      background: #FFFFFF;
      padding: 30px 50px;
    }
  }

</style>
